import {
  Link,
  isRouteErrorResponse,
  useRouteError,
  type ErrorResponse,
} from "@remix-run/react";

export default function DefaultErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    return <CatchBoundary caught={error} />;
  }

  const { message, stack } = error as Error;

  return <AppError message={message} stack={stack} />;
}

function AppError({ message, stack }: { message?: string; stack?: string }) {
  return (
    <>
      <div className="m-2 rounded bg-red-100 p-4">
        <h1 className="font-bolder mb-1 inline-flex items-center gap-4 text-2xl  text-red-900">
          {message || "App Error"}
        </h1>
        <p className="mb-1 text-lg">エラーが発生しました</p>
      </div>
    </>
  );
}

function CatchBoundary({ caught }: { caught: ErrorResponse }) {
  let message = caught.data;
  let data: any = {};
  if (/^[{[]/.test(caught.data)) {
    try {
      data = JSON.parse(caught.data);
      message = data.message;
    } catch (error) {
      console.error(error);
    }
  }
  switch (caught.status) {
    case 400:
      return <BadRequest message={message} data={data} />;
    case 401:
      return <Unauthorized message={message} data={data} />;
    case 403:
      return <Forbidden message={message} data={data} />;
    case 404:
      return <NotFound message={message} data={data} />;
    case 405:
      return <Invalid message={message} data={data} />;
    default:
      throw new Error(
        `Unexpected caught response with status: ${caught.status} ${caught.data}}`
      );
  }
}

function Unauthorized({ message, data }: { message?: string; data?: any }) {
  return (
    <div className="m-2 rounded bg-purple-100 p-4">
      <h1 className="font-bolder mb-1 inline-flex items-center gap-2 text-2xl text-purple-900">
        {message || "Unauthorized"}
      </h1>
      <p className="mb-1 text-lg">
        ログインが必要です。ログインしてください。
        <Link to="/auth/login">ログインページはこちら</Link>
      </p>
    </div>
  );
}

function BadRequest({ message, data }: { message?: string; data?: any }) {
  return (
    <div className="m-2 rounded bg-yellow-100 p-4">
      <h1 className="font-bolder mb-1 inline-flex items-center gap-2 text-2xl  text-red-900">
        {message || "Bad Request"}
      </h1>
      <p className="mb-1 text-lg">
        エラーが発生しました。入力内容を確認してください。
      </p>
    </div>
  );
}

function Invalid({ message, data }: { message?: string; data?: any }) {
  return (
    <div className="m-2 rounded bg-yellow-100 p-4">
      <h1 className="font-bolder mb-1 inline-flex items-center gap-2 text-2xl  text-red-900">
        {message || "Invalid"}
      </h1>
      <p className="mb-1 text-lg">
        エラーが発生しました。入力内容を確認してください。
      </p>
    </div>
  );
}

function Forbidden({ message, data }: { message?: string; data?: any }) {
  return (
    <div className="m-2 rounded bg-orange-100 p-4">
      <h1 className="font-bolder mb-1 inline-flex items-center gap-2 text-2xl  text-orange-900">
        {message || "Not Authorized"}
      </h1>
      <p className="mb-1 text-lg">アクセス権限がありません。</p>
    </div>
  );
}

function NotFound({ message, data }: { message?: string; data?: any }) {
  return (
    <div className="m-2 rounded bg-blue-100 p-4">
      <h1 className="font-bolder mb-1 inline-flex items-center gap-2 text-2xl  text-blue-900">
        {message || "Not Found"}
      </h1>
      <p className="mb-1 text-lg">該当のページが見つかりませんでした。</p>
    </div>
  );
}

export function ShowAllErrors() {
  return (
    <div className="flex flex-col gap-4">
      <AppError />
      <NotFound />
      <BadRequest />
      <Unauthorized />
      <Forbidden />
      <Invalid />
    </div>
  );
}
